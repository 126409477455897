body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px!important;
  line-height: 1.6;
  color: #333;
  /* overflow: hidden; */
}

body, html {
    height: initial;
}

#root {
}

pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: #ccc;
}

.fretboard {
    width: 1000px;
}

canvas {
    width: 1000px;
    height: 200px;
}

p {
    color: #666;
    margin: 2em 0;
}

.ui.dropdown {
    color: #666;
}

.ui.checkbox label, .ui.checkbox+label {
    color: #666!important;
}

p strong {
    color: #333;
}

.ui.selection.dropdown {
    border: none !important;
    box-shadow: none !important;
    background-color: #f8f8f8 !important;
    vertical-align: middle;
    margin: 0 1em 0 0;
}

::-webkit-scrollbar {
    display: none;
}

a {
    color: #6666ff;
}


.ui.search > .ui.input {
    width: 100%;
}

.ui.search > .ui.input > input {
    border: none;
    border-radius: 4px;
    background: #f8f8f8;
}

.ui.search .message.empty .header {
    color: #333;
}

.ui.search .message.empty .description {
    color: #666;
}

.ui.search .results {
    max-height: 20.83rem;
    overflow: auto;
    background: #f8f8f8;
}

.ui.category.search>.results .category .result:hover, .ui.search>.results .result:hover {
    /* background: #f0f0f0; */
    background: #ededff;
}


blockquote {
    margin: 0;
    border-left: 2px solid #ddd;
    padding-left: 1rem;
}

blockquote footer {
    color: #ddd;
}

blockquote ol {
    margin: 0;
}

.ui.popup {
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: #666;
    color: white;
    font-size:0.7rem;
}

.ui.popup:before {
    background: #666 !important;
    box-shadow: none;
}
